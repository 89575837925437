import React, { useEffect, lazy, Suspense } from "react";
import { Routes, Route, useNavigationType, useLocation } from "react-router-dom";
import Layout from "./pages/layout/layout";

// Lazy load components
const VaidhyaMeghaHome = lazy(() => import("./pages/home/vaidhya-megha-home"));
const VaidhyaMeghaContact = lazy(() => import("./pages/contact/vaidhya-megha-contact"));
const VaidhyaMeghaDataCloud = lazy(() => import("./pages/products/datacloud/VaidhyaMeghaDataCloud"));
const VaidhyaMeghaBIMigrator = lazy(() => import("./pages/products/bimigrator/VaidhyaMeghaBIMigrator"));
const VaidhyaMeghaBioSDLC = lazy(() => import("./pages/products/biosdlc/VaidhyaMeghaBioSDLC"));
const VaidhyaMeghaCSDLC = lazy(() => import("./pages/products/csdlc/VaidhyaMeghaCsdlc"));
const VaidhyaMeghaImageCloud = lazy(() => import("./pages/products/imagecloud/VaidhyaMeghaImageCloud"));
const VaidhyaMeghaTelePresence = lazy(() => import("./pages/products/telepresence/VaidhyaMeghaTelePresence"));
const VaidhyaMeghaBioinformatics = lazy(() => import("./pages/services/bioinformatics/VaidhyaMeghaBioinformatics"));
const VaidhyaMeghaClinicalTrials = lazy(() => import("./pages/services/clinicaltrials/VaidhyaMeghaClinicalTrials"));
const VaidhyaMeghaDigitalHealth = lazy(() => import("./pages/services/digitalhealth/VaidhyaMeghaDigitalHealth"));
const VaidhyaMeghaImaging = lazy(() => import("./pages/services/imaging/VaidhyaMeghaImaging"));
const AboutTeamInnovators = lazy(() => import("./pages/about/AboutTeamInnovators"));
const InsightfulPerspectives = lazy(() => import("./pages/insightful-perspectives/InsightfulPerspectives"));
const StrategicCollaborations = lazy(() => import("./pages/strategic-collaborations/StrategicCollaborations"));
const PrivacyPolicy = lazy(() => import("./pages/privacy-policy/PrivacyPolicy"));
const TermsConditions = lazy(() => import("./pages/terms-and-conditions/TermsConditions"));
const RefundCancellationPolicy = lazy(() => import("./pages/refund-and-cancellation-policy/RefundCancellationPolicy"));
const VaidhyaMeghaRapidMediCaptu = lazy(() => import("./pages/products/edc/VaidhyaMeghaRapidMediCaptu"));
const VaidhyaMeghaProductsServ = lazy(() => import("./pages/products-and-services/VaidhyaMeghaProductsServ"));

// Firebase imports and configuration
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAbvpYjPR_zHcy693XqUcdQe11ll2frcLA",
  authDomain: "vaidhyamegha-websites.firebaseapp.com",
  databaseURL: "https://vaidhyamegha-websites-default-rtdb.firebaseio.com",
  projectId: "vaidhyamegha-websites",
  storageBucket: "vaidhyamegha-websites.appspot.com",
  messagingSenderId: "378782912530",
  appId: "1:378782912530:web:8c636ad94bfc4b990e48cb",
  measurementId: "G-JSXPY1887B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics
const analytics = getAnalytics(app);

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }

    // Log page view
    logEvent(analytics, 'page_view', {
      page_path: pathname,
    });
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      // case "/":
      //   title = "";
      //   metaDescription = "";
      //   break;
      // Add more cases as needed for other routes
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Layout><VaidhyaMeghaHome /></Layout>} />
          <Route path="/about" element={<Layout><AboutTeamInnovators /></Layout>} />
          <Route path="/contact" element={<Layout><VaidhyaMeghaContact /></Layout>} />
          <Route path="/products/datacloud" element={<Layout><VaidhyaMeghaDataCloud /></Layout>} />
          <Route path="/products/bimigrator" element={<Layout><VaidhyaMeghaBIMigrator /></Layout>} />
          <Route path="/products/biosdlc" element={<Layout><VaidhyaMeghaBioSDLC /></Layout>} />
          <Route path="/products/csdlc" element={<Layout><VaidhyaMeghaCSDLC /></Layout>} />
          <Route path="/products/imagecloud" element={<Layout><VaidhyaMeghaImageCloud /></Layout>} />
          <Route path="/products/telepresence" element={<Layout><VaidhyaMeghaTelePresence /></Layout>} />
          <Route path="/products/edc" element={<Layout><VaidhyaMeghaRapidMediCaptu /></Layout>} />
          <Route path="/services/bioinformatics" element={<Layout><VaidhyaMeghaBioinformatics /></Layout>} />
          <Route path="/services/clinicaltrials" element={<Layout><VaidhyaMeghaClinicalTrials /></Layout>} />
          <Route path="/services/digitalhealth" element={<Layout><VaidhyaMeghaDigitalHealth /></Layout>} />
          <Route path="/services/imaging" element={<Layout><VaidhyaMeghaImaging /></Layout>} />
          <Route path="/insightful-perspectives" element={<Layout><InsightfulPerspectives /></Layout>} />
          <Route path="/strategic-collaborations" element={<Layout><StrategicCollaborations /></Layout>} />
          <Route path="/privacy-policy" element={<Layout><PrivacyPolicy /></Layout>} />
          <Route path="/terms-and-conditions" element={<Layout><TermsConditions /></Layout>} />
          <Route path="/refund-and-cancellation-policy" element={<Layout><RefundCancellationPolicy /></Layout>} />
          <Route path="/products-and-services" element={<Layout><VaidhyaMeghaProductsServ /></Layout>} />
        </Routes>
      </Suspense>

    </div>
  );
}

export default App;