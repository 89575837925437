import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "./dropdown-products.css";
import "./dropdown-services.css";
import './layout.css';

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const [bioinformaticsHover, setBioinformaticsHover] = useState(false);
  const [clinicalTrialsHover, setClinicalTrialsHover] = useState(false);
  const [digitalHealthHover, setDigitalHealthHover] = useState(false);
  const [imagingHover, setImagingHover] = useState(false);
  const [csdlcHover, setCsdlcHover] = useState(false);
  const [biosdlcHover, setBiosdlcHover] = useState(false);
  const [biMigratorHover, setBiMigratorHover] = useState(false);
  const [datacloudHover, setDatacloudHover] = useState(false);
  const [telepresenceHover, setTelepresenceHover] = useState(false);
  const [imagecloudHover, setImagecloudHover] = useState(false);
  const [showServicesDropdown, setShowServicesDropdown] = useState(false);
  const [showProductsDropdown, setShowProductsDropdown] = useState(false);
  const servicesRef = useRef(null);
  const productsRef = useRef(null);
  const [fbHover, setFbHover] = useState(false);
  const [xHover, setXHover] = useState(false);
  const [linkedinHover, setLinkedinHover] = useState(false);
  const [youtubeHover, setYoutubeHover] = useState(false);
  const [InstaHover, setInstaHover] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuToggle = () => {
    setShowMenu((prev) => !prev);
  };

  const handleNavigate = (path) => {
    console.log('Navigating to:', path); // Debug statement
    navigate(path);
    setShowMenu(false);
    setShowServicesDropdown(false);
    setShowProductsDropdown(false);
  };
  
  const handleDropdownItemClick = (event, navigateFunction) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    console.log('Dropdown Item Clicked'); // Debug statement
    navigateFunction(event);
    setShowMenu(false);
    setShowServicesDropdown(false);
    setShowProductsDropdown(false);
  };
  
  const handleClickOutside = (event) => {
    console.log('Clicked Outside:', event.target); // Debug statement
  
    // Check if the click is outside the services and products dropdowns and their respective toggles
    if (
      servicesRef.current &&
      !servicesRef.current.contains(event.target) &&
      productsRef.current &&
      !productsRef.current.contains(event.target) &&
      !event.target.closest('.menu-item')
    ) {
      console.log('Closing Dropdowns'); // Debug statement
      setShowServicesDropdown(false);
      setShowProductsDropdown(false);
    }
  };
  
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);  

  useEffect(() => {
    // Dynamically load the HubSpot form script
    const script = document.createElement('script');
    script.src = "//js.hsforms.net/forms/shell.js";
    document.body.appendChild(script);

    script.onload = () => {
      if(window.hbspt) {
        // Create the HubSpot form inside the target div
        window.hbspt.forms.create({
          portalId: "5818758", // Your actual Portal ID
          formId: "51f8b305-ab19-4629-b2fc-953e658b4135", // Your actual Form ID
          target: '#hubspotNewsletterForm'
        });
      }
    };

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);


  const toggleServicesDropdown = (event) => {
    event.stopPropagation();
    console.log('Services Dropdown Toggled:', !showServicesDropdown); // Debug statement
    setShowServicesDropdown((prev) => {
      return !prev;
    });
    if (!showServicesDropdown) {
      setShowProductsDropdown(false);
    }
  };
  
  const toggleProductsDropdown = (event) => {
    event.stopPropagation();
    console.log('Products Dropdown Toggled:', !showProductsDropdown); // Debug statement
    setShowProductsDropdown((prev) => {
      if (prev) {
        return false;
      } else {
        setShowServicesDropdown(false);
        return true;
      }
    });
  }; 
  
  // const handleStartupIndiaClick = () => {
  //   window.open('/certificates/startupindia.pdf', '_blank');
  // };

  const handleStartupIndiaClick = () => {
    window.open('https://recognition-be.startupindia.gov.in/s3/download/document/RECOGNITION_CERTIFICATE/abac5f9b-de36-4740-98d9-d36d27e02520.pdf', '_blank');
  };
    
  const handleHomeClick = (event) => {
    event.preventDefault();
    navigate('/');
  };
  const  handleAboutClick = (event) => {
    event.preventDefault();
    navigate('/about')
  }
  const handleContactClick = (event) => {
    event.preventDefault();
    navigate('/contact');
  };
  const handleBlogClick = () => {
    navigate('/blog');
  };
  const handleDataCloudClick = (event) => {
    event.preventDefault();
    navigate('/products/datacloud');
  };
  const handleBiMigratorClick = (event) => {
    event.preventDefault();
    navigate('/products/bimigrator')
  }
  const  handleBioSDLCClick = (event) => {
    event.preventDefault();
    navigate('/products/biosdlc')
  }
  const  handleCSDLCClick = (event) => {
    event.preventDefault();
    navigate('/products/csdlc')
  }
  const  handleImageCloudClick = (event) => {
    event.preventDefault();
    navigate('/products/imagecloud')
  } 
  const  handleTelePresenceClick = (event) => {
    event.preventDefault();
    navigate('/products/telepresence')
  }
  const  handleEDCClick = (event) => {
    event.preventDefault();
    navigate('/products/edc')
  }
  const  handleBioInformaticsClick = (event) => {
    event.preventDefault();
    navigate('/services/bioinformatics')
  }
  const  handleClinicalTrialsClick = (event) => {
    event.preventDefault();
    navigate('/services/clinicaltrials')
  }
  const  handleDigitalHealthClick = (event) => {
    event.preventDefault();
    navigate('/services/digitalhealth')
  }
  const  handleImagingClick = (event) => {
    event.preventDefault();
    navigate('/services/imaging')
  }
  const  handlePrivacyPolicyClick = (event) => {
    event.preventDefault();
    navigate('/privacy-policy')
  }
  const handleTermsAndConditionsClick = (event) => {
    event.preventDefault();
    navigate('/terms-and-conditions')
  }
  const handleRefundCancellationClick = (event) => {
    event.preventDefault();
    navigate('/refund-and-cancellation-policy')
  }

  return (
    <>
      <header className="header">
        <div className="logo-parent">
          <img
            onClick={handleHomeClick}
            className="iimb-logo-1"
            loading="lazy"
            alt="vaidhyamegha logo group"
            src="/layout/vmlogogroup.svg"
          />
        </div>
        <div className='header-menu'>
          <img
            className="menu-icon"
            alt="Menu"
            src={showMenu ? "/layout/menu-icon-cross.png" : "/layout/menu-icon.png"}
            onClick={handleMenuToggle}
          />
          {showMenu && (
            <div className="dropdown-menu">
              <a href="/" className="menu-item header-link" onClick={(event) => handleDropdownItemClick(event, () => handleNavigate('/'))}>
                Home
              </a>
              <a href="/about" className="menu-item header-link" onClick={(event) => handleDropdownItemClick(event, handleAboutClick)}>About</a>
              <div className="menu-item" ref={servicesRef} onClick={toggleServicesDropdown}>
                Services
                <img
                  className="service-arrow-icon2"
                  alt="arrow icon"
                  src={showServicesDropdown ? "/home/dd-arrow-up.svg" : "/home/dd-arrow.svg"}
                />
              </div>
              {showServicesDropdown && (
                <div className={`dropdown-services ${showServicesDropdown ? 'show-dropdown-services' : ''}`} ref={servicesRef} onClick={(event) => event.stopPropagation()}>
                  <a href="/services/bioinformatics" className="bioinformatics-in-service2 footer-link" onClick={(event) => handleDropdownItemClick(event, handleBioInformaticsClick)}>
                    <img className="bio-informatics-service-icon2" alt="Bioinformatics" src={bioinformaticsHover ? '/home/bioinformatics-group-icon-hover.svg' : '/home/bioinformatics-group-icon.svg'} />
                    <div className='imaging5'>Bioinformatics</div>
                  </a>
                  <a href="/services/clinicaltrials" className="clinical-trials-in-service2 footer-link" onClick={(event) => handleDropdownItemClick(event, handleClinicalTrialsClick)}>
                    <img className="clinical-trials-service-icon2" alt="Clinical Trials" src={clinicalTrialsHover ? '/home/clinical-trials-group-icon-hover.svg' : '/home/clinical-trials-group-icon.svg'} />
                    <div className='imaging5'>Clinical Trials</div>
                  </a>
                  <a href="/services/digitalhealth" className="digital-health-in-service2 footer-link" onClick={(event) => handleDropdownItemClick(event, handleDigitalHealthClick)}>
                    <img className="digital-health-service-icon2" alt="Digital Health" src={digitalHealthHover ? '/home/digital-health-vector-hover.svg' : '/home/digital-health-vector.svg'} />
                    <div className='imaging5'>Digital Health</div>
                  </a>
                  <a href="/services/imaging" className="imaging-in-service2 footer-link" onClick={(event) => handleDropdownItemClick(event, handleImagingClick)}>
                    <img className="imaging-service-icon2" alt="Imaging" src={imagingHover ? '/home/imaging-group-icon-hover.svg' : '/home/imaging-group-icon.svg'} />
                    <div className='imaging5'>Imaging</div>
                  </a>
                </div>
              )}
              <div className="menu-item" ref={productsRef} onClick={toggleProductsDropdown}>
                Products
                <img
                  className="products-arrow-icon"
                  alt="arrow icon"
                  src={showProductsDropdown ? "/home/dd-arrow-up.svg" : "/home/dd-arrow.svg"}
                />
              </div>
              {showProductsDropdown && (
                <div className={`dropdown-products ${showProductsDropdown ? 'show-dropdown-products' : ''}`} ref={productsRef} onClick={(event) => event.stopPropagation()}>
                  <a href="/products/datacloud" className="datacloud3-m footer-link" onClick={(event) => handleDropdownItemClick(event, handleDataCloudClick)}>
                    <img className="product-icon-m" alt="DataCloud" src={datacloudHover ? "/home/datacloud-group-hover.svg" : "/home/datacloud-group.svg"} />
                    <div className="csdlc3">DataCloud</div>
                  </a>
                  <a href="/products/bimigrator" className="bi-migrator7-m footer-link" onClick={(event) => handleDropdownItemClick(event, handleBiMigratorClick)}>
                    <img className="product-icon-m" alt="BI Migrator" src={biMigratorHover ? "/home/bimigrator-vector-hover.svg" : "/home/bimigrator-vector.svg"} />
                    <div className="csdlc3">BI Migrator</div>
                  </a>
                  <a href="/products/biosdlc" className="biosdlc3-m footer-link" onClick={(event) => handleDropdownItemClick(event, handleBioSDLCClick)}>
                    <img className="product-icon-m" alt="BioSDLC" src={biosdlcHover ? "/home/biosdlc-vector-hover.svg" : "/home/biosdlc-vector.svg"} />
                    <div className="csdlc3">BioSDLC</div>
                  </a>
                  <a href="/products/csdlc" className="csdlc2-m footer-link" onClick={(event) => handleDropdownItemClick(event, handleCSDLCClick)}>
                    <img className="product-icon-m" alt="CSDLC" src={csdlcHover ? "/home/csdlc-vector-hover.svg" : "/home/csdlc-vector.svg"} />
                    <div className="csdlc3">CSDLC</div>
                  </a>
                  <a href="/products/imagecloud" className="imagecloud1-m footer-link" onClick={(event) => handleDropdownItemClick(event, handleImageCloudClick)}>
                    <img className="product-icon-m" alt="ImageCloud" src={imagecloudHover ? "/home/image-cloud-hover.svg" : "/home/image-cloud.svg"} />
                    <div className="csdlc3">ImageCloud</div>
                  </a>
                  <a href="/products/telepresence" className="telepresence1-m footer-link" onClick={(event) => handleDropdownItemClick(event, handleTelePresenceClick)}>
                    <img className="product-icon-m" alt="TelePresence" src={telepresenceHover ? "/home/telepresence-vector-hover.svg" : "/home/telepresence-vector.svg"} />
                    <div className="csdlc3">TelePresence</div>
                  </a>
                  <a href="/products/edc" className="edc1-m footer-link" onClick={(event) => handleDropdownItemClick(event, handleEDCClick)}>
                    <img className="product-icon-m" alt="Rapid MediCapture EDC" src={imagecloudHover ? "/home/edc-icon-hover.svg" : "/home/edc-icon.svg"} />
                    <div className="csdlc3">Rapid MediCapture EDC</div>
                  </a>
                </div>
              )}
              <div className="menu-item mobile-blog-page" onClick={() => handleNavigate('/blog')}>Blog</div>
              <a href="/contact" className="menu-item header-link" onClick={(event) => handleDropdownItemClick(event, handleContactClick)}>Contact</a>
            </div>
          )}
        </div>
        <div className="header-inner">
          <div className="home-parent">
            <div className="cta-header-parent">
              <div className="cta-header">
                <a href='tel:+919618986039' className="layerx" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <img className="line-icon" alt="line" src="/home/line.svg" />
                  <div className="frame">+91 9618986039</div>
                </a>
                <a href="mailto:contact@vaidhyamegha.com" className="layerx1" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <img className="vector-icon" alt="mail icon" src="/home/vector-1.svg" />
                  <div className="contactvaidhyameghacom" >
                    contact@vaidhyamegha.com
                  </div>
                </a>
              </div>
              <div className="rectangle">
                <a href="/" className="home footer-link" onClick={handleHomeClick}>
                  Home
                </a>
                <a href="/about" className="about footer-link" onClick={handleAboutClick}>
                  About
                </a>
                <div className="services" ref={servicesRef} onClick={toggleServicesDropdown}>
                  <div className="services1">Services</div>
                  <div className="subscribenow">
                    <img
                      className="service-arrow-icon"
                      alt="arrow icon"
                      src="/home/service-arrow.svg"
                    />
                  </div>
                  {showServicesDropdown && (
                    <div className={`dropdown-services ${showServicesDropdown ? 'show-dropdown-services' : ''}`}>
                      <a
                        href="/services/bioinformatics"
                        className="bioinformatics-in-service footer-link"
                        onClick={(event) => handleDropdownItemClick(event, handleBioInformaticsClick)}
                        onMouseEnter={() => setBioinformaticsHover(true)}
                        onMouseLeave={() => setBioinformaticsHover(false)}
                      >
                        <img
                          className="bio-informatics-service-icon"
                          alt="Bioinformatics"
                          src={bioinformaticsHover ? '/home/bioinformatics-group-icon-hover.svg' : '/home/bioinformatics-group-icon.svg'}
                        />
                        <div className="dropdown-services2"></div>
                        <div className="imaging4">Bioinformatics</div>
                      </a>
                      <a
                        href="/services/clinicaltrials"
                        className="clinical-trials-in-service footer-link"
                        onClick={(event) => handleDropdownItemClick(event, handleClinicalTrialsClick)}
                        onMouseEnter={() => setClinicalTrialsHover(true)}
                        onMouseLeave={() => setClinicalTrialsHover(false)}
                      >
                        <img
                          className="clinical-trials-service-icon"
                          alt="Clinical Trials"
                          src={clinicalTrialsHover ? '/home/clinical-trials-group-icon-hover.svg' : '/home/clinical-trials-group-icon.svg'}
                        />
                        <div className="dropdown-services2"></div>
                        <div className="imaging4">Clinical Trials</div>
                      </a>
                      <a
                        href="/services/digitalhealth"
                        className="digital-health-in-service footer-link"
                        onClick={(event) => handleDropdownItemClick(event, handleDigitalHealthClick)}
                        onMouseEnter={() => setDigitalHealthHover(true)}
                        onMouseLeave={() => setDigitalHealthHover(false)}
                      >
                        <img
                          className="digital-health-service-icon"
                          alt="Digital Health"
                          src={digitalHealthHover ? '/home/digital-health-vector-hover.svg' : '/home/digital-health-vector.svg'}
                        />
                        <div className="dropdown-services2"></div>
                        <div className="imaging4">Digital Health</div>
                      </a>
                      <a
                        href="/services/imaging"
                        className="imaging-in-service footer-link"
                        onClick={(event) => handleDropdownItemClick(event, handleImagingClick)}
                        onMouseEnter={() => setImagingHover(true)}
                        onMouseLeave={() => setImagingHover(false)}
                      >
                        <img
                          className="imaging-service-icon"
                          alt="Imaging"
                          src={imagingHover ? '/home/imaging-group-icon-hover.svg' : '/home/imaging-group-icon.svg'}
                        />
                        <div className="dropdown-services2"></div>
                        <div className="imaging4">Imaging</div>
                      </a>
                    </div>
                  )}
                </div>
                <div className="products" ref={productsRef} onClick={toggleProductsDropdown}>
                  <div className="products1">Products</div>
                  <div className="products-arrow-wrapper">
                    <img className="products-arrow-icon" alt="" src="/home/service-arrow.svg" />
                  </div>
                  {showProductsDropdown && (
                    <div className={`dropdown-products ${showProductsDropdown ? 'show-dropdown-products' : ''}`}>
                      <a
                        href="/products/csdlc"
                        className="csdlc2 footer-link"
                        onClick={(event) => handleDropdownItemClick(event, handleCSDLCClick)}
                        onMouseEnter={() => setCsdlcHover(true)}
                        onMouseLeave={() => setCsdlcHover(false)}
                      >
                        <img className="icon" alt="CSDLC"
                          src={csdlcHover ? "/home/csdlc-vector-hover.svg" : "/home/csdlc-vector.svg"} />
                        <div className="csdlc3">CSDLC</div>
                      </a>
                      <a
                        href="/products/biosdlc"
                        className="biosdlc3 footer-link"
                        onClick={(event) => handleDropdownItemClick(event, handleBioSDLCClick)}
                        onMouseEnter={() => setBiosdlcHover(true)}
                        onMouseLeave={() => setBiosdlcHover(false)}
                      >
                        <img className="vector-icon4" alt="BioSDLC"
                          src={biosdlcHover ? "/home/biosdlc-vector-hover.svg" : "/home/biosdlc-vector.svg"} />
                        <div className="csdlc3">BioSDLC</div>
                      </a>
                      <a
                        href="/products/bimigrator"
                        className="bi-migrator7 footer-link"
                        onClick={(event) => handleDropdownItemClick(event, handleBiMigratorClick)}
                        onMouseEnter={() => setBiMigratorHover(true)}
                        onMouseLeave={() => setBiMigratorHover(false)}
                      >
                        <img className="vector-icon5" alt="BI Migrator"
                          src={biMigratorHover ? "/home/bimigrator-vector-hover.svg" : "/home/bimigrator-vector.svg"} />
                        <div className="csdlc3">BI Migrator</div>
                      </a>
                      <a
                        href="/products/datacloud"
                        className="datacloud3 footer-link"
                        onClick={(event) => handleDropdownItemClick(event, handleDataCloudClick)}
                        onMouseEnter={() => setDatacloudHover(true)}
                        onMouseLeave={() => setDatacloudHover(false)}
                      >
                        <img className="datacloud-child1" alt="DataCloud"
                          src={datacloudHover ? "/home/datacloud-group-hover.svg" : "/home/datacloud-group.svg"} />
                        <div className="csdlc3">DataCloud</div>
                      </a>
                      <a
                        href="/products/telepresence"
                        className="telepresence1 footer-link"
                        onClick={(event) => handleDropdownItemClick(event, handleTelePresenceClick)}
                        onMouseEnter={() => setTelepresenceHover(true)}
                        onMouseLeave={() => setTelepresenceHover(false)}
                      >
                        <img className="vector-icon6" alt="TelePresence"
                          src={telepresenceHover ? "/home/telepresence-vector-hover.svg" : "/home/telepresence-vector.svg"} />
                        <div className="csdlc3">TelePresence</div>
                      </a>
                      <a
                        href="/products/imagecloud"
                        className="imagecloud1 footer-link"
                        onClick={(event) => handleDropdownItemClick(event, handleImageCloudClick)}
                        onMouseEnter={() => setImagecloudHover(true)}
                        onMouseLeave={() => setImagecloudHover(false)}
                      >
                        <img className="cloud-photo-camera-icon" alt="ImageCloud"
                          src={imagecloudHover ? "/home/image-cloud-hover.svg" : "/home/image-cloud.svg"} />
                        <div className="csdlc3">ImageCloud</div>
                      </a>
                      <a
                        href="/products/edc"
                        className="edc1 footer-link"
                        onClick={(event) => handleDropdownItemClick(event, handleEDCClick)}
                        onMouseEnter={() => setImagecloudHover(true)}
                        onMouseLeave={() => setImagecloudHover(false)}
                      >
                        <img className="edc-icon1" alt="Rapid MediCapture EDC"
                          src={imagecloudHover ? "/home/edc-icon-hover.svg" : "/home/edc-icon.svg"} />
                        <div className="csdlc3">Rapid MediCapture EDC</div>
                      </a>
                    </div>
                  )}
                </div>
                <div className="text2">
                  <div className="blog" onClick={handleBlogClick}>
                    Blog
                  </div>
                </div>
                <a href="/contact" className="contact footer-link" onClick={handleContactClick}>
                  Contact
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      
      <main>
        {children} {/* Page-specific content will be injected here */}
      </main>

      {/* Newsletter section JSX here */}
      
      <section className="frame-unlockinnovation">
        <div className="newsletter">
          <div className="newsletter-child" />
          <div className="newsletter1">
            <div className="subscribe-text">
              <button className="subscribe-c-t-a">
                <div className="subscribe-c-t-a-child" />
                <div className="subscribe-to-our">
                  Subscribe to Our Newsletter
                </div>
              </button>
              <h1 className="stay-connected-with-container">
                <span className="stay-connected-with">{`Stay Connected with `}</span>
                <span className="vaidhyamegha2">VaidhyaMegha</span>
              </h1>
              <div id="hubspotNewsletterForm" className="location-frame3"></div>
              {/* <div className="subscribe-now-button">
                <div className="subscribe-now-button-child" />
                <div className="location-frame">
                  <input 
                    type="email" 
                    className="email-input" 
                    placeholder="Email address..." 
                    name="email" 
                    required 
                  />
                </div>
                <button className="subscribe-now">
                  <div className="subscribe-now-child" />
                  <b className="subscribe-now1">Subscribe now</b>
                </button>
              </div> */}
            </div>
          </div>
          <img
            className="newsletter-1-icon"
            loading="lazy"
            alt="Email communication"
            src="/home/newsletter-1@2x.png"
          />
        </div>
        <footer className="footer1">
          <div className="footer-child" />
          <div className="line-framecopyright">
            <div className="social-media-icons">
              <div className="social-icons-row">
                <div className="logo-with-text">
                  <div className="contact-us-details">
                    <img className="footer-logo-icon1" alt="Vaidhyamegha logo" src="/home/logo-1.svg" onClick={handleHomeClick} />
                  </div>
                  <div className='LineDiv'></div>
                  <div className="social-media-icons1">
                    <a href="https://www.facebook.com/vaidhyamegha/" target="_blank" rel="noopener noreferrer" className="wrapper-icon-fb" onMouseEnter={() => setFbHover(true)} onMouseLeave={() => setFbHover(false)}>
                      <img
                        className="icon-fb"
                        loading="lazy"
                        alt="Facebook"
                        src={fbHover ? "/home/icon-fb-hover.svg" : "/home/icon-fb.svg"}
                      />
                    </a>
                    <a href="https://twitter.com/SVaidhyamegha" target="_blank" rel="noopener noreferrer" className="wrapper-icon-x" onMouseEnter={() => setXHover(true)} onMouseLeave={() => setXHover(false)}>
                      <img
                        className="icon-x"
                        loading="lazy"
                        alt="Twitter"
                        src={xHover ? "/home/icon-x-hover.svg" : "/home/icon-x.svg"}
                      />
                    </a>
                    <a href="https://www.linkedin.com/company/vaidhyamegha/" target="_blank" rel="noopener noreferrer" className="wrapper-icon-linkedin" onMouseEnter={() => setLinkedinHover(true)} onMouseLeave={() => setLinkedinHover(false)}>
                      <img
                        className="icon-linkedin"
                        loading="lazy"
                        alt="LinkedIn"
                        src={linkedinHover ? "/home/icon-linkedin-hover.svg" : "/home/icon-linkedin.svg"}
                      />
                    </a>
                    <a href="https://www.youtube.com/@VaidhyaMegha" target="_blank" rel="noopener noreferrer" className="wrapper-icon-youtube" onMouseEnter={() => setYoutubeHover(true)} onMouseLeave={() => setYoutubeHover(false)}>
                      <img
                        className="icon-youtube"
                        loading="lazy"
                        alt="YouTube"
                        src={youtubeHover ? "/home/icon-youtube-hover.png" : "/home/icon-youtube.png"}
                      />
                    </a>
                    <a href="https://www.instagram.com/vaidhyamegha/" target="_blank" rel="noopener noreferrer" className="wrapper-icon-insta" onMouseEnter={() => setInstaHover(true)} onMouseLeave={() => setInstaHover(false)}>
                      <img
                        className="icon-insta"
                        loading="lazy"
                        alt="Instagram"
                        src={InstaHover ? "/home/icon-insta-hover.svg" : "/home/icon-insta.svg"}
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="linked-in-icon">
                <div className="products6">Products</div>
                <div className="datacloud-bi-migrator-biosdlc">
                  <p className="datacloud2 hover-item">
                    <a href="/products/datacloud" className="footer-link" onClick={handleDataCloudClick}>DataCloud</a>
                  </p>
                  <p className="bi-migrator6 hover-item">
                    <a href="/products/bimigrator" className="footer-link" onClick={handleBiMigratorClick}>BI Migrator</a>
                  </p>
                  <p className="biosdlc2 hover-item">
                    <a href="/products/biosdlc" className="footer-link" onClick={handleBioSDLCClick}>BioSDLC</a>
                  </p>
                  <p className="csdlc1 hover-item">
                    <a href="/products/csdlc" className="footer-link" onClick={handleCSDLCClick}>CSDLC</a>
                  </p>
                  <p className="imagecloud hover-item">
                    <a href="/products/imagecloud" className="footer-link" onClick={handleImageCloudClick}>ImageCloud</a>
                  </p>
                  <p className="telepresence hover-item">
                    <a href="/products/telepresence" className="footer-link" onClick={handleTelePresenceClick}>TelePresence</a>
                  </p>
                  <p className="telepresence hover-item">
                    <a href="/products/edc" className="footer-link" onClick={handleEDCClick}>Rapid MediCapture EDC</a>
                  </p>
                </div>
                <div className='LineDiv'></div>
              </div>              
              <div className="newsletter-frame">
                <div className="services3" >Services</div>
                <div className="bio-informatics-clinical-trial-container">
                  <p className="bio-informatics1 hover-item">
                    <a href="/services/bioinformatics" className="footer-link" onClick={handleBioInformaticsClick}>Bio Informatics</a>
                  </p>
                  <p className="clinical-trials2 hover-item">
                    <a href="/services/clinicaltrials" className="footer-link" onClick={handleClinicalTrialsClick}>Clinical Trials</a>
                  </p>
                  <p className="digital-health2 hover-item">
                    <a href="/services/digitalhealth" className="footer-link" onClick={handleDigitalHealthClick}>Digital Health</a>
                  </p>
                  <p className="imaging2 hover-item">
                    <a href="/services/imaging" className="footer-link" onClick={handleImagingClick}>Imaging</a>
                  </p>
                </div>
                <div className='LineDiv'></div>
              </div>   
              <div className="subscribe-button">
                <div className="reach-us">Reach us</div>
                <div className="location-input">
                  <div className="contact-info-section">
                    <div className="vector-frame">
                      <div className="products-text">
                        <a href="tel:+919618986039" className='phoneno' style={{ textDecoration: 'none', color: 'inherit' }}>
                          <img
                              className="home-category-brand"
                              alt="phone icon"
                              src="/home/vector-2.svg"
                          />
                          <div className="bio-s-d  hover-item2">+91 9618986039</div>
                        </a>
                        <a href="mailto:contact@vaidhyamegha.com" className='mailtovaid' style={{ textDecoration: 'none', color: 'inherit' }}>
                          <img
                              className="create-a-chatbot-gpt-using-pyt"
                              alt="mail icon"
                              src="/home/vector-3.svg"
                            />
                          <div className="contactvaidhyameghacom1 hover-item2">
                            contact@vaidhyamegha.com
                          </div>
                        </a>
                      </div>
                    </div>
                    <a href="https://www.google.com/maps/search/?api=1&query=3-5-139%2F3%2F3%2F2%2C+Shiva+Nagar+Colony%2C+Siva+Nagar%2C+Hyderguda%2C+Hyderabad%2C+Telangana+500048"
                      target='_blank' className="reach-us-section " style={{ textDecoration: 'none', color: 'inherit' }}>
                      <div className="footer-location">
                        <img
                          className="location-icon"
                          alt="location icon"
                          src="/home/location.svg"
                        />
                      </div>
                      <div className="attapur-hyderguda-hyderabad-container hover-item2" >
                        <p className='attapur-hyderguda-hyderabad'>3-5-139/3/3/2, Shiva Nagar Colony,</p>
                        <p className="attapur-hyderguda-hyderabad">
                          Attapur, Hyderguda, Hyderabad,
                        </p>
                        <p className="telangana-500048">Telangana 500048</p>
                      </div>
                    </a>
                  </div>
                </div>
                <div className='LineDiv'></div>
              </div>             
              <div className='followus-mobile'>
                <div className='followus-text'>
                  FOLLOW US
                </div>
                <div className="social-media-icons1-mobile">
                  <a href="https://www.facebook.com/vaidhyamegha/" target="_blank" rel="noopener noreferrer" className="wrapper-icon-fb" onMouseEnter={() => setFbHover(true)} onMouseLeave={() => setFbHover(false)}>
                    <img
                      className="icon-fb"
                      loading="lazy"
                      alt="Facebook"
                      src={fbHover ? "/home/icon-fb-hover.svg" : "/home/icon-fb.svg"}
                    />
                  </a>
                  <a href="https://twitter.com/SVaidhyamegha" target="_blank" rel="noopener noreferrer" className="wrapper-icon-x" onMouseEnter={() => setXHover(true)} onMouseLeave={() => setXHover(false)}>
                    <img
                      className="icon-x"
                      loading="lazy"
                      alt="Twitter"
                      src={xHover ? "/home/icon-x-hover.svg" : "/home/icon-x.svg"}
                    />
                  </a>
                  <a href="https://www.linkedin.com/company/vaidhyamegha/" target="_blank" rel="noopener noreferrer" className="wrapper-icon-linkedin" onMouseEnter={() => setLinkedinHover(true)} onMouseLeave={() => setLinkedinHover(false)}>
                    <img
                      className="icon-linkedin"
                      loading="lazy"
                      alt="LinkedIn"
                      src={linkedinHover ? "/home/icon-linkedin-hover.svg" : "/home/icon-linkedin.svg"}
                    />
                  </a>
                  <a href="https://www.youtube.com/@VaidhyaMegha" target="_blank" rel="noopener noreferrer" className="wrapper-icon-youtube" onMouseEnter={() => setYoutubeHover(true)} onMouseLeave={() => setYoutubeHover(false)}>
                    <img
                      className="icon-youtube"
                      loading="lazy"
                      alt="YouTube"
                      src={youtubeHover ? "/home/icon-youtube-hover.png" : "/home/icon-youtube.png"}
                    />
                  </a>
                  <a href="https://www.instagram.com/vaidhyamegha/" target="_blank" rel="noopener noreferrer" className="wrapper-icon-insta" onMouseEnter={() => setInstaHover(true)} onMouseLeave={() => setInstaHover(false)}>
                    <img
                      className="icon-insta"
                      loading="lazy"
                      alt="Instagram"
                      src={InstaHover ? "/home/icon-insta-hover.svg" : "/home/icon-insta.svg"}
                    />
                  </a>                 
                </div>
                <div className='LineDiv'></div>
              </div>
            </div>
          </div>
          <div className='LineDiv1'></div>         
          <div className='iso-logo-container'>
            <div className='iso-logo-cert'>
              <div className='iso-logo-certifications'>
                <div className='certifications-text'>
                  Certifications
                </div>
              </div>
              <div className="iso-logo-row">
                <img className="footer-logo iso-logo1" alt="ISO Logo" src="/layout/ISO_27001.png" />
                <span className="vertical-line">|</span>
                <img className="footer-logo iso-logo1" alt="ISO Logo" src="/layout/ISO_9001.png" />
                <span className="vertical-line">|</span>
                <img className="footer-logo startupindia" alt="Startup india logo" src="/layout/startupindia.png" onClick={handleStartupIndiaClick} />
              </div>
            </div>
            <div className='partners-vm-div'>
              <div className='partners-vm'>
                <div className='partners-text'>Partners</div>
                <div className='aws-partner-div'>
                  <img 
                    className='aws-partner-logo'
                    src='/layout/aws-partner-logo.png'
                    alt='AWS Partner logo'
                  />
                </div>
              </div>
            </div>           
          </div>
          <div className='LineDiv1'></div>
          <div className='LineDiv'></div>
          <div className='copyright-div'>
            <div className="copyright-2018-24">
              © Copyright 2018 - 24 VaidhyaMegha Pvt. Ltd. All Rights Reserved.
            </div>
            <div className='privacy-div'>
              <a href="/privacy-policy" className='privacy-policy11 hover-item2 footer-link' onClick={(event) => handleDropdownItemClick(event, handlePrivacyPolicyClick)}>
                Privacy Policy 
              </a>
              <div className='straight-line'> | </div>
              <a href="/terms-and-conditions" className='terms-and-conditions1 hover-item2 footer-link' onClick={(event) => handleDropdownItemClick(event, handleTermsAndConditionsClick)}>
                Terms & Conditions
              </a>
              <div className='straight-line'>
                | 
              </div>
              <a href="/refund-and-cancellation-policy" className='refund-and-cancellation1 hover-item2 footer-link' onClick={(event) => handleDropdownItemClick(event, handleRefundCancellationClick)}>
                Refund and Cancellation Policy
              </a>
            </div>
          </div>        
        </footer>
      </section>
    </>
  );
};

export default Layout;
